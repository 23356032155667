<template>
  <div class="pages">
    <!--顶部头像-->
    <div class="headerInfo">
      <img class="headBack" src="../static/images/main/banner_null.png" width="100%"/>
      <div class="backView">
<!--        <span class="Back" @click="back()">返回</span>-->
      </div>
      <div class="userInfo">
        <img v-if="userClassInfo.Sex == '1'" :src="userClassInfo.Head != ''?userClassInfo.Head:headImgUrl" class="round_icon"
             :style="{border:'solid 2px #AACC03'}">
        <img v-if="userClassInfo.Sex == '2'" :src="userClassInfo.Head != ''?userClassInfo.Head:headImgUrl" class="round_icon"
             :style="{border:'solid 2px #E75296'}">
        <span>{{ userClassInfo.Name }}</span>
      </div>
    </div>
    <div class="info" v-show="false">
      <ul>
        <li>
          <em>{{ userClassInfo.Height }}</em>
          <span>身高cm</span>
        </li>
        <li>
          <em>{{ userClassInfo.Age }}</em>
          <span>年龄</span>
        </li>
        <li>
          <em>{{ userClassInfo.Weight }}</em>
          <span>体重kg</span>
        </li>
        <li>
          <em>{{ userClassInfo.Sex }}</em>
          <span>性别</span>
        </li>
      </ul>
    </div>

    <!--课程详情-->
    <div class="pageDetail">
      <span class="btnBack" @click="back()" v-if="false">返回</span>
      <!--课程标题-->
      <div class="classDetail">
        <div class="detailTitle" :style="{ color: classDetail.ClassColor,border : '1px solid'+classDetail.ClassColor}">
          <span class="className">{{ classDetail.ClassName }}</span>
          <span class="classTime">{{ classDetail.BeginTime | filterTime }} -{{
              classDetail.EndTime | filterTime
            }}</span>
        </div>
      </div>
      <!--会员基本信息-->
      <div class="memberInfo">
        <span>上课时基本信息 ｜年龄 </span><em>{{ userClassInfo.Age }}</em>
        <span> 岁 ｜身高 </span><em>{{ userClassInfo.Height }}</em>
        <span> cm ｜ 体重 </span><em>{{ userClassInfo.Weight }}</em>
        <span> Kg</span>
      </div>

      <!--课程基本信息-->
      <div class="classInfo">
        <ul style="border-top: 1px solid #CCC; padding-top: 15px">
          <li>
            <img src="../static/images/report/report_cle_total.png">
            <div>
              <em>{{ classDetail.Cle }}</em>
              <span>卡路里</span>
            </div>
          </li>
          <li>
            <img src="../static/images/report/report_ck.png">
            <div>
              <em>{{ classDetail.Ck }}</em>
              <span>CK</span>
            </div>
          </li>
          <li>
            <img src="../static/images/report/report_ei.png">
            <div>
              <em>{{ classDetail.Ei }}</em>
              <span>运动强度</span>
            </div>
          </li>
        </ul>

        <ul>
          <li>
            <img src="../static/images/report/report_duration.png">
            <div>
              <em>{{ classDetail.SportDuration }}分钟</em>
              <span>上课时长</span>
            </div>

          </li>
          <li>
            <img src="../static/images/report/report_sort.png">
            <div>
              <em>{{ classDetail.StaOrder }}</em>
              <span>排名</span>
            </div>
          </li>
          <li>
            <img src="../static/images/report/report_member.png">
            <div>
              <em>{{ classDetail.StdUserNum }}</em>
              <span>上课人数</span>
            </div>
          </li>
        </ul>
      </div>

      <div v-show="visibleChartAcPercent">
        <h5 class="sportPower">运动强度分布</h5>
        <!--运动强度柱状图-->
        <!--      <div class="chartCalorie" id="activePercent" style="margin-top: 20px;width: 100%;margin-left: 0"/>-->
        <div class="chartCalorie" id="activePercent" :style="{width:screenWidth,marginTop:'20px',marginLeft:'0'}"/>
      </div>

      <!--运动心率折线图-->
      <!--      <div class="chartCalorie" id="sportHeartRate" style="margin-top: 10px;margin-left: 0;"/>-->
      <div v-show="visibleChartHeartRate" class="chartCalorie" id="sportHeartRate"
           :style="{width:screenWidth,marginTop:'10px',marginLeft:'0',height:'300px'}"/>

      <div v-show="visibleChartHeartRate" class="chartCalorie" id="sportEiChart"
           :style="{width:screenWidth,marginTop:'10px',marginLeft:'0',height:'300px'}"/>


      <!--动作评分-->
      <div class="pageScore" v-show="classDetail.ShopId == 6">
        <div style="height: 10px;background: #F2F2F2;"></div>
        <div class="scoreTitle">
          <span>动作质量反馈</span>
        </div>
        <ul class="scoreContent">
          <li v-for="scoreItem in classScoreDetail">
            <span class="left">{{ scoreItem.EaName }}</span>
            <span class="right">
              {{ scoreItem.EaScore }}
              <em :style="{background:scoreItem.Color}">{{ scoreItem.Evaluate }}</em>
            </span>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import Crypto from '@/tools/crypto.js'

import bottomTab from '../components/bottomTab'
import {
  GetSportPercentByDuId,
  GetUserHistoryInfoByDuId,
  WxTplClassOverDetail,
  PersonClassDetail,
  WxTplScoreClassQuery,
  GetHrValueInfoByDuId
} from '../api/getApiRes.js'

let qs = require('qs');
import Global from '../Global.js'
// 引入基本模板
let echarts = require('echarts/lib/echarts')
// 引入柱状图组件
require('echarts/lib/chart/bar')
require('echarts/lib/chart/line')
// 引入提示框和title组件
require('echarts/lib/component/title')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/dataZoom')
require('echarts/lib/component/legend')
require('echarts/lib/component/grid')

export default {
  data() {
    return {
      DuId: '',
      StdId: '',
      userMd5: '',
      thisTab: '上课统计',
      screenWidth: document.body.clientWidth + 'px',
      headImgUrl: require('../static/images/mine/header_default.png'),
      classDetail: {},//课程基础信息
      userClassInfo: {
        Name:'',
        Head:'',
        Age:'',
        Height:'',
        Weight:'',
        Sex:'',
        StaticHr:''
      },//用户上课时的体态信息
      classScoreDetail: {},//用户评分课结果
      visibleChartAcPercent: false,//课堂活动百分比柱状图显示控制
      visibleChartHeartRate: false,//心率折线图显示控制
      visiblePageScore: false,//评分结果内容显示控制
      active: 0
    }
  },
  watch: {
    '$route'(to) {
      if (to.name == 'reportLessonDetailWithoutLogin') {
        this.getUserInfo();//获取用户基本信息
      }
    },
  },
  mounted() {
    let that = this;
    // let stringEnCode = this.$aes.encrypt('{"du_id":306,"user_md5":"2871720baf0a3585df678691cacfb411"}');
    // console.log("加密后",stringEnCode);
    // console.log("解密后",this.$aes.decrypt(stringEnCode));

    // let aesFormat = stringEnCode.replaceAll('=','_');
    // aesFormat = stringEnCode.replaceAll('+','*');
    // aesFormat = stringEnCode.replaceAll('/','-');

    let aesSrc = this.$route.query.parm;

    let aesFormat = aesSrc.replaceAll('_','=');
    aesFormat = aesFormat.replaceAll('*','+');
    aesFormat = aesFormat.replaceAll('-','/');

    let aesDest = this.$aes.decrypt(aesFormat);
    // console.log("解密",aesDest);

    let paramObj = {};
    paramObj = JSON.parse(aesDest);
    // console.log("得到对象",paramObj);

    that.DuId = paramObj.du_id;
    that.userMd5 = paramObj.user_md5;

    that.getUserInfo();//获取用户基本信息

  },
  methods: {
    //返回上一页
    back() {
      this.$router.push({path: '/reportLesson'});
    },
    //获取用户基本信息
    getUserInfo() {
      let that = this;
      let param = {
        duId: that.DuId,
        userMd5: that.userMd5
      };
      let postData = qs.stringify(param);
      WxTplClassOverDetail(postData).then(res => {
        let json = res;
        if (json.Code == 0) {
          that.StdId = json.Rs.StdId;
          that.classDetail = json.Rs;
          that.getUserClassInfo();//获取用户上课时的基本信息
          that.loadActivePercentData();//载入课程柱状图信息
          that.getUserClassHr();//获取用户上课时的心率
          if (that.classDetail.IsScore === 0) {
            that.visiblePageScore = false;
          } else {
            that.visiblePageScore = true;
            that.getClassScoreInfo();
          }
        } else {
          that.$toast.message(json.Memo);
        }
      })
    },
    //获取用户上课时的基本信息
    getUserClassInfo() {
      let that = this;
      let param = {
        token: localStorage.token,
        duId: that.DuId,
        userMd5: that.userMd5
      };
      let postData = qs.stringify(param);
      GetUserHistoryInfoByDuId(postData).then(res => {
        let json = res;
        if (json.Code == 0) {
          that.userClassInfo = json.Rs;
        } else {
          // that.$toast.message(json.Memo);
        }
      })
    },
    //加载课堂活动百分比数据源
    loadActivePercentData() {
      let that = this;
      let dataLine = [];
      let durationMinutes = 0;
      let param = {
        token: localStorage.token,
        duId: that.DuId,
        userMd5: that.userMd5
      };
      let postData = qs.stringify(param);
      GetSportPercentByDuId(postData).then(res => {
        let json = res;
        // console.log(json);
        if (json.Code == 0) {
          dataLine = json.Rs.DataY;
          durationMinutes = json.Rs.Duration;
          that.visibleChartAcPercent = true;
          that.loadActivePercentChart(dataLine, durationMinutes);//载入课程运动强度柱状图
        } else {
          dataLine = {};
          durationMinutes = {};
          that.visibleChartAcPercent = false;
          // that.$toast.message(json.Memo);
        }
      })
    },
    //加载课堂学生心率数据源
    getUserClassHr() {
      let that = this;
      let dataHrX = [];
      let dataHrY = [];
      let acWeight = {};//let acWeight = {"100": 100, "89": 80, "39": 10, "69": 40, "54": 20, "79": 60};
      that.visibleChartHeartRate = false;

      let param = {
        duId: that.DuId,
        userMd5: that.userMd5
      };
      let postData = qs.stringify(param);
      GetHrValueInfoByDuId(postData).then(res => {
        let json = res;
        // console.log(json);
        if (json.Code == 0) {
          if (json.Rs.DataX && json.Rs.DataY) {
            dataHrX = json.Rs.DataX;
            dataHrY = json.Rs.DataY;
            acWeight = JSON.parse(json.Rs.AcWeight);
            that.visibleChartHeartRate = true;
            that.analyseEi(dataHrX, dataHrY, acWeight);//处理心率数据
          }
        } else {
          // that.$toast.message(json.Memo);
        }
      })
    },
    //计算各个点的Ei数据
    // float activePercent = (((float) this.getRealHr() - (float) this.getStaticHr()) / (float) ((208 - 0.7 * (float) this.getAge()) - (float) this.getStaticHr())) * 100;
    analyseEi(xDataTime, yDataHr, acWeight) {
      let that = this;
      let eiDataArr = [];
      let staticHr = that.userClassInfo.StaticHr, age = that.userClassInfo.Age, calorie = that.classDetail.Cle,
          lessonDuration = that.classDetail.SportDuration;

      let acArr = [];
      let acMap = deepClone(acWeight);
      for (let i in acMap) {
        acMap[i] = 0;
        acArr.push(parseInt(i));
      }
      // console.log(acWeight);
      // console.log(acMap);
      // console.log(acArr);

      let caloriePerMin = calorie / lessonDuration;
      let eiValueMin = 1000, eiValueMax = 0;
      let hrValueMin = 200, hrValueMax = 0;
      //测试数据
      // yDataHr = [79, 111, 92, 130, 180, 145, 109, 156, 92, 178, 180, 198, 145];
      // xDataTime = yDataHr;

      //遍历心率点
      for (let i in yDataHr) {
        // console.log('------------------');
        let analyseHr = yDataHr[i];//实时心率
        let analyseAcPercent = ((analyseHr - staticHr) / ((208 - 0.7 * age) - staticHr)) * 100;//实时运动百分比
        // console.log(analyseHr, '-acPercent-', analyseAcPercent);

        // console.log('acMap', acMap);
        let belongArea = 39;
        //判断属于哪一个区间
        for (let j = acArr.length - 1; j >= 0; j--) {
          if (analyseAcPercent <= acArr[j] && analyseAcPercent > acArr[j - 1]) {
            belongArea = acArr[j];
            // console.log(j, '属于区间', acArr[j]);
            break;
          }
          if (j == 0) {
            // console.log(analyseAcPercent, '属于区间39');
          }
        }

        //填入对应的acMap
        acMap[belongArea] += 1;
        // console.log('acMap', acMap);

        //计算当前的Ei值
        let mapValueSum = 0;
        for (let k in acMap) {
          mapValueSum += acMap[k];
        }
        let currScore = 0;
        for (let l in acMap) {
          if (acMap[l] != 0) {
            // console.log('acPercent:', l, '\ttimes:', acMap[l], '\ttotalTimes:', mapValueSum, '\tpercentScore:', acWeight[l]);
            currScore += (acMap[l] / mapValueSum) * acWeight[l];
          }
        }
        let analyseEi = parseInt((caloriePerMin * currScore).toString());

        // console.log(analyseEi, '--', caloriePerMin, '--', currScore);
        eiDataArr.push(analyseEi);
        if (hrValueMin > analyseHr) {
          hrValueMin = analyseHr;
        }
        if (hrValueMax < analyseHr) {
          hrValueMax = analyseHr;
        }
        if (eiValueMin > analyseEi) {
          eiValueMin = analyseEi;
        }
        if (eiValueMax < analyseEi) {
          eiValueMax = analyseEi;
        }
      }
      that.loadSportHrChart(xDataTime, yDataHr, (hrValueMin > 30 ? hrValueMin : 30) - 30, hrValueMax + 30);//载入课程心率折线图
      that.loadSportEiChart(xDataTime, eiDataArr, (eiValueMin > 200 ? eiValueMin : 200) - 200, eiValueMax + 200);//载入课程Ei折线图
      // console.log('hrValueMin', hrValueMin, 'hrValueMax', hrValueMax, 'eiValueMin', eiValueMin, 'eiValueMax', eiValueMax);
    },
    //加载课程运动强度柱状图
    loadActivePercentChart(dataLine, durationMinutes) {
      let echarts = require('echarts');
      let activePercentChart = echarts.init(document.getElementById('activePercent'));
      let dataMax = 100;
      // dataLine = [11, 11, 11, 11, 11, 11];
      // durationMinutes = 50;

      //计算二次柱状图占比（最多的为100%，其余的数据对应其比值）
      let valuePercent = [], valueMinutes = [];
      let maxMins = 0;
      for (let i in dataLine) {
        let perMintus = dataLine[i] / 100 * durationMinutes;
        valueMinutes.push(perMintus);
        if (maxMins < perMintus) {
          maxMins = perMintus;
        }
      }
      for(let i in valueMinutes){
        let perPercnet = valueMinutes[i]/maxMins*100;
        valuePercent.push(parseInt(perPercnet));
      }
      console.log(maxMins,valueMinutes);


      // 绘制图表
      let option = {
        // 设置图表的位置
        grid: [
          {
            left: '8%',//左侧留白
            top: '12%',//顶部留白
            bottom: '8%',//底部留白
            width: '80%',//宽度占比
            // width: 500,
            containLabel: true
          },
          {
            top: '12%',
            bottom: '8%',
            containLabel: true
          }
        ],
        // 提示框组件
        tooltip: {
          trigger: 'axis', // 触发类型, axis: 坐标轴触发
          axisPointer: {
            // 指示器类型  'line' 直线指示器 'shadow' 阴影指示器 'none' 无指示器
            // 'cross' 十字准星指示器 其实是种简写，表示启用两个正交的轴的 axisPointer
            type: 'none'
          },
          color: '#cdd3ee', // 文字颜色
          // 提示框浮层内容格式器，支持字符串模板和回调函数两种形式 折线（区域）图、柱状（条形）图、K线图
          // {a}（系列名称），{b}（类目值），{c}（数值）, {d}（无）
          formatter: '{b}<br/>{a0}: {c}%'
        },
        // X轴
        xAxis: [{
          max: dataMax,
          show: false
        }],
        yAxis: [{
          axisTick: 'none',
          axisLine: 'none',
          offset: '10',
          // inverse: true,
          axisLabel: {
            color: '#000000', //y轴字体颜色
            fontSize: '16'
          },
          // data: ['激活放松', '动态热身', '脂肪燃烧', '糖分消耗', '心肺训练', '峰值训练']
          data: ['峰值训练', '心肺训练', '糖分消耗', '脂肪燃烧', '动态热身', '激活放松']
        },
          {
            axisTick: 'none',
            axisLine: 'none',
            show: false,
            axisLabel: {
              color: '#ffffff',
              fontSize: '16'
            },
            data: [1, 1, 1, 1, 1, 1]
          }, {
            axisLine: {
              lineStyle: {
                color: 'rgba(0,0,0,0)' //y轴线颜色
              }
            },
            data: []
          },
          {  //设置柱状图右边参数
            // show: true,
            // inverse: true,
            // data: dataLine,
            axisLine: {
              show: false
            },
            // splitLine: {
            //   show: false
            // },
            // axisTick: {
            //   show: false
            // }
          },
        ],
        // 系列列表
        series: [{
          name: '占比',
          type: 'bar',
          stack: 'b',
          yAxisIndex: 0,
          data: valuePercent,
          color: '#ffffff',
          fontSize: '16',
          label: {
            show: true,
            position: 'insideLeft',
            distance: 5,
            formatter: function (param) {
              // let sportWeight = (param.value / dataMax).toFixed(2) * 100;
              // return parseInt(sportWeight) + '%'
              return param.value + '%'
            },
          },
          barWidth: 11,
          itemStyle: {
            color: function (params) {
              //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
              // let colorList = ['#028FE1', '#6D29FA', '#0AB105', '#B9CB01', '#EA8813', '#D11122'];
              let colorList = ['#D11122', '#EA8813', '#B9CB01', '#0AB105', '#6D29FA', '#028FE1'];
              return colorList[params.dataIndex];
            },
            borderRadius: [100, 100, 100, 100, 100, 100],
          },
          z: 2
        }, { //背景灰框
          name: '白框',
          type: 'bar',
          yAxisIndex: 1,
          barGap: '-100%',//设置-100% 则表示灰色柱状图与红色柱状图重合
          data: [99.8, 99.9, 99.9, 99.9],
          barWidth: 11,
          itemStyle: {
            color: '#EFEFEF',
            borderRadius: [100, 100, 100, 100, 100, 100],
          },
          z: 1  // 设置维度越高这表示覆盖低的
        }, //进度条底框
          {
            name: '外框',
            type: 'bar',
            yAxisIndex: 2,
            barGap: '-100%',
            data: [100, 100, 100, 100, 100, 100],
            barWidth: 13,
            label: {//右边的文字
              show: true,
              position: 'right',
              distance: 5,
              color: '#000000',
              formatter: function (data) {
                if (dataLine[data.dataIndex] < 1) {
                  return '0分钟';
                }
                let actualDuration = parseInt(dataLine[data.dataIndex] / 100 * durationMinutes);
                if (actualDuration == 0) {
                  return '<1分钟';
                } else {
                  return actualDuration + "分钟";
                }
              },
            },
            //进度条底色
            itemStyle: {
              color: '#EFEFEF',
              borderRadius: [100, 100, 100, 100, 100, 100],
            },
            z: 0
          }
        ]
      }
      activePercentChart.setOption(option);
    },
    //课程运动心率折线图
    loadSportHrChart(dataX, dataY, hrValueMin, hrValueMax) {
      // 基于准备好的dom，初始化echarts实例
      let sportHeartRateChart = echarts.init(document.getElementById('sportHeartRate'));
      let options = {
        title: {
          left: 'center',
          text: '心率趋势'
        },
        tooltip: {
          // 当trigger为’item’时只会显示该点的数据，为’axis’时显示该列下所有坐标轴所对应的数据。
          trigger: 'axis',
          // 提示框的位置
          position: function (pt) {
            return [pt[0], '50%'];
          }
        },
        // toolbox：这是ECharts中的工具栏。内置有导出图片、数据视图、动态类型切换、数据区域缩放、重置五个工具。
        // toolbox: {
        // feature 各工具配置项: dataZoom 数据区域缩放;restore 配置项还原;saveAsImage下载为图片;magicType动态类型切换
        // feature: {
        //   dataZoom: {
        //     yAxisIndex: 'none'  // y轴不缩放，Index默认为0
        //   },
        // restore: {},
        // saveAsImage: {}
        // ,magicType: {
        //     type: ['bar', 'line']
        // }
        // }
        // },
        xAxis: [{
          type: 'category', // category为一级分类,适用于离散的类目数据
          boundaryGap: false,  // 无间隙
          data: dataX
        }],
        yAxis: [{
          min: hrValueMin,
          max: hrValueMax,  //设置左侧最大值
          type: 'value', // 'value' 数值轴，适用于连续数据。
          boundaryGap: [0, '100%'] // 分别表示数据最小值和最大值的延伸范围，可以直接设置数值或者相对的百分比，
        }],
        // 内置于坐标系中，使用户可以在坐标系上通过鼠标拖拽、鼠标滚轮、手指滑动（触屏上）来缩放或漫游坐标系
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 100
        }, {
          start: 0,
          end: 5,
          //柄的 icon 形状，支持路径字符串
          //handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
          //控制手柄的尺寸，可以是像素大小，也可以是相对于 dataZoom 组件宽度的百分比，默认跟 dataZoom 宽度相同。
          handleSize: '20%',
          // handleStyle: {
          //   color: 'pink',
          //   shadowBlur: 1,      // shadowBlur图片阴影模糊值，shadowColor阴影的颜色
          //   shadowColor: 'red',
          //   shadowOffsetX: 2,
          //   shadowOffsetY: 2
          // }
        }],
        series: [
          {
            name: '瞬时心率',
            type: 'line',
            // smooth: true,  // 开启平滑处理。true的平滑程度相当于0.5
            symbol: 'none', // 标记的图形。
            sampling: 'average', //  取过滤点的平均值
            itemStyle: {
              lineStyle: {
                width: 0.1,
                color: '#ff6f4a' /*折线的颜色*/
              },
              color: '#ff6f4a'/*图例(legend)的颜色,不是图例说明文字的颜色*/
            },
            areaStyle: {    // 区域填充样式。
              color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 0, color: '#ffffff'},
                    /* {offset: 0.5, color: '#c4e1ff'},*/
                    {offset: 1, color: '#ffffff'}
                  ]
              )
            },
            data: dataY
          }
        ]
      };
      sportHeartRateChart.setOption(options);
    },
    //课程运动心率折线图
    loadSportEiChart(dataX, dataY, eiValueMin, eiValueMax) {
      // 基于准备好的dom，初始化echarts实例
      let sportHeartRateChart = echarts.init(document.getElementById('sportEiChart'));
      let options = {
        title: {
          left: 'center',
          text: '运动强度趋势'
        },
        tooltip: {
          // 当trigger为’item’时只会显示该点的数据，为’axis’时显示该列下所有坐标轴所对应的数据。
          trigger: 'axis',
          // 提示框的位置
          position: function (pt) {
            return [pt[0], '50%'];
          }
        },
        // toolbox：这是ECharts中的工具栏。内置有导出图片、数据视图、动态类型切换、数据区域缩放、重置五个工具。
        // toolbox: {
        // feature 各工具配置项: dataZoom 数据区域缩放;restore 配置项还原;saveAsImage下载为图片;magicType动态类型切换
        // feature: {
        //   dataZoom: {
        //     yAxisIndex: 'none'  // y轴不缩放，Index默认为0
        //   },
        // restore: {},
        // saveAsImage: {}
        // ,magicType: {
        //     type: ['bar', 'line']
        // }
        // }
        // },
        xAxis: [{
          type: 'category', // category为一级分类,适用于离散的类目数据
          boundaryGap: false,  // 无间隙
          data: dataX
        }],
        yAxis: [{
          min: eiValueMin,
          max: eiValueMax,  //设置左侧最大值
          type: 'value', // 'value' 数值轴，适用于连续数据。
          boundaryGap: [0, '100%'] // 分别表示数据最小值和最大值的延伸范围，可以直接设置数值或者相对的百分比，
        }],
        // 内置于坐标系中，使用户可以在坐标系上通过鼠标拖拽、鼠标滚轮、手指滑动（触屏上）来缩放或漫游坐标系
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 100
        }, {
          start: 0,
          end: 5,
          //柄的 icon 形状，支持路径字符串
          //handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
          //控制手柄的尺寸，可以是像素大小，也可以是相对于 dataZoom 组件宽度的百分比，默认跟 dataZoom 宽度相同。
          handleSize: '20%',
          handleStyle: {
            color: 'pink',
            shadowBlur: 3,      // shadowBlur图片阴影模糊值，shadowColor阴影的颜色
            shadowColor: 'red',
            shadowOffsetX: 2,
            shadowOffsetY: 2
          }
        }],
        series: [
          {
            name: '时刻Ei',
            type: 'line',
            // smooth: true,  // 开启平滑处理。true的平滑程度相当于0.5
            symbol: 'none', // 标记的图形。
            sampling: 'average', //  取过滤点的平均值
            itemStyle: {
              lineStyle: {
                width: 0.1,
                color: '#ff6f4a' /*折线的颜色*/
              },
              color: '#ff6f4a'/*图例(legend)的颜色,不是图例说明文字的颜色*/
            },
            areaStyle: {    // 区域填充样式。
              color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 0, color: '#ffffff'},
                    /* {offset: 0.5, color: '#c4e1ff'},*/
                    {offset: 1, color: '#ffffff'}
                  ]
              )
            },
            data: dataY
          }
        ]
      };
      sportHeartRateChart.setOption(options);
    },
    //获取课程评分信息
    getClassScoreInfo() {
      let that = this;
      let param = {
        duId: that.DuId,
        userMd5: that.userMd5
      };
      let postData = qs.stringify(param);
      WxTplScoreClassQuery(postData).then(res => {
        let json = res;
        if (json.Code == 0) {
          that.classScoreDetail = json.Rs;
        } else {
          that.classScoreDetail = [];
          // that.$toast.message(json.Memo);
        }
      })
    },
  },
  filters: {
    hidePhone: function (val) {
      let str = String(val)
      let len = str.length;
      if (len >= 7) {
        let reg = str.slice(-8, -4)
        return str.replace(reg, "****")
      } else if (len < 7 && len >= 6) {
        let reg = str.slice(-4, -2)
        return str.replace(reg, "**")
      }
    },
    filterTime: function (value) {
      if (!value) {
        return 0;
      } else {
        let resStr = dateFormatWithoutZone(value, 16);
        return resStr.substr(10, 15);
      }
    },
    fmtTime: function (value) {
      if (!value) {
        return 0
      } else {
        // js默认使用毫秒
        value = value * 1000
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let m = date.getMinutes();
        m = m < 10 ? ('0' + m) : m;
        let s = date.getSeconds();
        s = s < 10 ? ('0' + s) : s;
        return y + '-' + MM + '-' + d;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    // A跳转到B，B页面停留在A页面的滚动位置；解决方法：将scrollTop设置为0
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      if (to.name == 'reportLessonDetail') {
        vm.getUserInfo();
      }
    });
  },
  components: {
    bottomTab
  }
}
</script>

<style scoped>
.pages {
  display: block;
  margin: 0 auto;
  background: #F2F2F2;
  overflow: scroll;
  height: 100vh;
}

.headerInfo {
  position: relative;
}

.headerInfo .headBack {
  height: 120px;
  position: absolute;
  z-index: 1;
}

.headerInfo .backView {
  left: 5%;
  top: 1.5rem;
  position: absolute;
  z-index: 2;
  text-align: center;
}

.headerInfo .Back {
  width: 50px;
  height: 25px;
  font-size: 14px;
  border-radius: 2px;
  line-height: 26px;
  color: #F4F4F4;
  display: block;
  margin-top: 60px;
  margin-left: -10px;
  background: rgba(255, 255, 255, 0.24);
  text-align: center;
}


.headerInfo .userInfo {
  left: 42%;
  top: 1.25rem;
  position: absolute;
  z-index: 2;
  text-align: center;
}

.headerInfo .userInfo span {
  font-size: 16px;
  color: white;
  display: block;
  margin-top: 5px;
}

.round_icon {
  width: 64px;
  height: 64px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 3px;
  border: solid 2px white;
}

.info {
  background: #fff;
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  margin-top: 120px;
}

.info ul {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.info li {
  width: 25%;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  float: left;
  text-align: center;
}

.info li:nth-child(2) {
  border-color: #e2e2e2;
}

.info li:nth-child(3) {
  border-color: #e2e2e2;
  border-left: 0;
}

.info li em {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  color: #363636;
  font-size: 20px;
  text-align: center;
}

.info li span {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  color: #c4c4c4;
  font-size: 12px;
  text-align: center;
}

.tag {
  height: 252px;
}

.tag .card {
  width: 45%;
  float: left;
  height: 106px;
  margin-left: 3%;
  margin-top: 20px;
  border-radius: 13px;
  background: linear-gradient(#FFFFFF, #FFD4D3);
}

.tag .card .row1 img {
  margin-left: 10px;
  margin-top: 10px;
  width: 17px;
  height: 18px;
  float: left;
}

.tag .card .row1 em {
  font-family: "PingFang SC";
  font-size: 12px;
  line-height: 40px;
  margin-left: 7px;
  color: #3b3b3b;
}

.tag .card .row2 span {
  font-family: "PingFang SC";
  font-size: 22px;
  font-weight: bold;
  margin-left: 35px;
  color: #363636;
}

.tag .card .row2 em {
  font-family: "PingFang SC";
  font-size: 10px;
  margin-left: 5px;
  color: #909090;
}

.tag .card .row3 span {
  font-family: "PingFang SC";
  font-size: 12px;
  margin-left: 35px;
  color: #363636;
}

.tag .card .row3 em {
  font-family: "PingFang SC";
  font-size: 12px;
  margin-left: 5px;
  color: #363636;
}

.reportCalorie {
  margin-top: 10px;
  background: #FFF;
  color: #FF5800;
}

.reportCalorie .title {
  padding-top: 10px;
  padding-bottom: 10px;
  background: #FFF;
}

.fa-square {
  background: #FF5800;
  height: 8px;
  width: 8px;
  float: left;
  margin-left: 15px;
  margin-top: 5px;
  margin-right: 5px;
}

.fa-circle {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #c40b0b;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
}

.sportPower {
  font-size: 18px;
  margin-bottom: -30px;
  font-weight: bold;
  font-family: "PingFang SC";
}

.chartCalorie {
  text-align: center;
  width: 95%;
  height: 200px;
  margin-top: -35px;
  margin-left: 5%;
}

.classReport {
  background: #F2F2F2;
}

.reportCalorie ul {
  padding-bottom: 100px;
  margin-top: 0px;
}

.classReport li {
  width: 100%;
  margin-top: 1px;
  background: #FFF;
  height: 66px;
  padding-left: 15px;
  float: left;
}

.classReport li .className {
  width: 25%;
  height: 28px;
  line-height: 28px;
  background: #EBFFE3;
  overflow: hidden;
  font-size: 14px;
  padding: 1px 11px;
  border-radius: 250px;
  float: left;
  margin-top: 20px;
  /*border: solid 1px #37CB00;*/
  text-align: center;
  color: #333;
}

.classReport li .calorieToTal {
  float: left;
  text-align: center;
  display: block;
  width: 25%;
  margin-top: 15px;
}

.calorieToTal em {
  width: 100%;
  overflow: hidden;
  color: black;
  display: block;
  font-size: 16px;
}

.calorieToTal span {
  width: 100%;
  overflow: hidden;
  display: block;
  font-family: "PingFang SC";
  font-weight: normal;
  font-size: 10px;
  color: #C9C9C9;
}

/*pageClass*/
.pageClass {
  text-align: center;
  margin-top: 15px;
}

.pageClass em {
  text-align: center;
  font-size: 16px;
}

.dayList .dayDetail {
  background: #FFF;
  margin-left: 5%;
  margin-right: 5%;
  height: 205px;
  text-align: center;
  border-radius: 6px;
  margin-top: 15px;
}

.dayDetail .dayTitle {
  font-size: 12px;
  float: right;
  margin-right: 23px;
  margin-top: 16px;
  overflow: hidden;
  display: block;
  font-family: "PingFang SC";
  font-weight: normal;
  color: #9A9A9A;
}

.dayDetail .className {
  width: 85px;
  height: 28px;
  line-height: 28px;
  background: #EBFFE3;
  overflow: hidden;
  font-size: 14px;
  padding: 1px 11px;
  border-radius: 250px;
  float: left;
  margin-left: 15px;
  margin-top: 13px;
  border: solid 1px #37CB00;
  text-align: center;
  color: #333;
}

.dayDetail .sportDetail {
  padding-top: 50px;
  text-align: center;
}

.sportDetail ul {
  width: 90%;
  margin-top: 5px;
  margin-left: 5%;
  height: 65px;
  border-bottom: 1px solid #EFEFEF;
}

.sportDetail ul li em i {
  float: left;
  margin-top: 10px;
  margin-left: 10px;
}

.sportDetail li {
  width: 33%;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  text-align: center;
  float: left;
}

.sportDetail li:nth-child(2) {
  border-color: #e2e2e2;
}

.sportDetail li em {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  font-size: 22px;
  font-weight: bold;
  text-align: right;
  padding-right: 8px;
}

.sportDetail li span {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  color: #c4c4c4;
  font-size: 12px;
  text-align: right;
  padding-right: 8px;
}

.sportDetail .sportDuration {
  text-align: center;
  margin: 0 auto;
  width: 90%;
  height: 40px;
  border-bottom: 1px solid #EFEFEF;
}

.sportDetail .sportDuration em {
  color: #028FE1;
  margin-left: 5px;
  margin-right: 5px;
}

.sportDetail .sportDuration span {
  font-size: 14px;
  line-height: 40px;
}

.sportDetail .sportView {
  height: 40px;
}

.sportDetail .sportView span {
  float: left;
  margin-left: 5%;
  margin-top: 10px;
  color: #848484;
}

.sportDetail .sportView em {
  float: right;
  font-weight: normal;
  color: #C4C4C4;
  margin-right: 5%;
  margin-top: 10px;
}


.pageDetail {
  text-align: center;
  background: #FFF;
  margin-top: 120px;
}

.pageDetail .btnBack {
  float: left;
  margin-left: 15px;
  margin-top: 14px;
  font-size: 16px;
  overflow: hidden;
  display: block;
  font-family: "PingFang SC";
  color: #ACACAC;
}

.pageDetail .classDetail {
  display: inline-block;
  *display: inline;
  /*margin-right: 10px;*/
  zoom: 1;
  margin-top: 30px;
}

.pageDetail .detailTitle {
  float: left;
  /*background: #EBFFE3;*/
  border-radius: 12px;
  padding: 1px 15px;
  /*border: solid 1.5px #37CB00;*/
}

.detailTitle span {
  display: block;
}

.pageDetail .memberInfo {
  margin-top: 5px;
}

.memberInfo em {
  color: #FFA310;
  font-size: 12px;
}

.memberInfo span {
  font-size: 12px;
  color: #909090;
}

.classInfo {
  background: #FFF;
  text-align: center;
}

.classInfo ul {
  width: 80%;
  margin-left: 10%;
  height: 40px;
  border-bottom: 1px solid #CCC;
  padding-bottom: 50px
}


.classInfo li {
  width: 33%;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  text-align: center;
  float: left;
}

.classInfo li img {
  float: left;
  margin-left: 10%;
}

.classInfo li div {
  float: right;
  margin-right: 5%;
}

.classInfo li:nth-child(2) {
  border-color: #e2e2e2;
}

.classInfo li em {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  font-weight: bold;
  color: black;
  font-size: 16px;
  text-align: right;
}

.classInfo li span {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  color: #c4c4c4;
  font-size: 12px;
  text-align: center;
}

/**
动作评分结果选项
 */

.pageScore {
  background: #F2F2F2;
  margin-top: 10px;
}

.pageScore .scoreTitle {
  background: #FFF;
}

.pageScore span {
  height: 50px;
  font-size: 17px;
  font-weight: bold;
  padding-top: 10px;
  text-align: center;
  line-height: 50px;
  font-family: "PingFang SC";
}

.pageScore .scoreContent {
  margin-top: 5px;
  font-weight: normal;
}

.pageScore .scoreContent li {
  width: 100%;
  background: #FFF;
  height: 50px;
  border-bottom: solid 1px #F2F2F2;
}

.pageScore .scoreContent li span {
  font-weight: normal;
}

.pageScore .scoreContent li:first-child .right em {
  /*background: #f2ff24;*/
}

.pageScore .scoreContent li:first-child span {
  font-weight: bold;
}

.pageScore .scoreContent li .left {
  width: 45%;
  float: left;
  line-height: 30px;
  text-align: right;
  padding-right: 10%;
  display: block;
  border-right: 1px solid #F2F2F2;
}

.pageScore .scoreContent li .right {
  /*background: #EBFFE3;*/
  width: 50%;
  float: left;
  text-align: left;
  padding-left: 10%;
  line-height: 30px;
  border-right: 1px solid #F2F2F2;
  display: block;
}

.pageScore .scoreContent li .right em {
  font-weight: normal;
  margin-right: 10%;
  color: white;
  border-radius: 15px;
  height: 25px;
  line-height: 25px;
  width: 70px;
  text-align: center;
  font-size: 14px;
  float: right;
}

.user {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  height: 58px;
  background: #fff;
  padding: 17px 3%;
  margin-bottom: 10px;
}

.user img {
  width: 26.29px;
  height: 26.29px;
  float: left;
  margin-right: 11px;
}

.user span {
  font-family: "PingFang SC";
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  color: #3b3b3b;
}

.user em {
  font-family: "PingFang SC";
  font-weight: normal;
  font-size: 18px;
  text-align: left;
  color: #3b3b3b;
  float: right;
}

.user p {
  font-family: "PingFang SC";
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #ffb43c;
  float: right;
  padding: 0;
  margin: 0;
  margin-left: 3px;
  line-height: 26px;
}

.value {
  width: 100%;
  /*height: 131px;*/
  background: #fff;
  padding: 13px 0;
}

.value .vTitle {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  text-align: center;

  font-family: "PingFang SC";
  font-weight: 300;
  font-size: 15px;
}

.vTitle i {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 250px;
  margin-right: 3px;
}

.vTitle.year {
  color: #E75296;
}

.vTitle.year i {
  background: #e75296;
}

.vTitle.price {
  color: #FFB43C;
}

.vTitle.price i {
  background: #FFB43C;
}

.vNum {
  width: 100%;
  /*height: 25px;*/
  overflow: hidden;
  display: block;
  margin: 5px auto;
  margin-bottom: 0;
}

.center {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.vNum em {
  position: relative;
  float: right;
  bottom: 20px;
  font-family: "PingFang SC";
  font-weight: 500;
  font-size: 12px;
  color: #c9c9c9;
  padding-right: 3%;
}

/deep/ .linear-progress {
  width: 90%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  border-radius: 250px;
}

.limit {
  width: 90%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  margin-top: 8px;
}

.limit em {
  font-family: "PingFang SC";
  font-weight: 300;
  font-size: 12px;
  text-align: right;
  color: #e75296;
  padding-left: 10px;
}

.limit span {
  font-family: "PingFang SC";
  font-weight: 300;
  font-size: 12px;
  text-align: right;
  color: #909090;
  float: right;
  text-align: right;
  padding-right: 10px;
}

.dataSum {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  background: #fff;
  margin-top: 10px;
}

.dataSum .row {
  width: 90%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  padding: 2% 0;
}

.dataSum .row em {
  float: left;
  font-family: "PingFang SC";
  font-weight: 300;
  font-size: 18px;
  text-align: right;
  color: #3b3b3b;
}

.dataSum .row span {
  font-family: "PingFang SC";
  font-weight: 500;
  font-size: 18px;
  text-align: right;
  color: #3b3b3b;
  float: right;
}

.row .mid {
  width: 66%;
  height: 0px;
  background: transparent;
  border: 1px dotted #e0e0e0;
  float: left;
  margin-top: 11px;
  margin-right: 5px;
  margin-left: 10px;
}

.row .midl {
  width: 54%;
  height: 0px;
}

.login_btn {
  width: 96%;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 30px;
  padding-left: 3%;
  padding-right: 3%;
  background: #E75296;
  color: #fff;
  font-size: 14px;
  text-align: center;
  border: none;
  padding: 0;
  font-size: 24px;
}

/deep/ .mu-warning-text-color {
  float: left;
}

.vNum span {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  text-align: center;
}

@media only screen and (max-width: 640px) {

}

@media only screen and (max-width: 480px) {

}

@media only screen and (max-width: 375px) {
  .vNum em {
    padding-right: 0 !important;
  }

  .row .mid {
    width: 49%;
  }

  .row .midl {
    width: 44%;
    height: 0px;
  }
}

@media only screen and (max-width: 360px) {
  .vNum em {
    padding-right: 0 !important;
  }

  .row .mid {
    width: 49%;
  }

  .row .midl {
    width: 44%;
    height: 0px;
  }
}

@media only screen and (max-width: 320px) {

}

@media only screen and (min-width: 641px) {

}
</style>
